<template>
  <div class="sxjsyzhb">
    <div style="width: 900px; margin: 0 auto;">
      <div class="zs">
        <p>主诉</p>
        <el-input class="sr" v-model="postData.S_OP_Json.CL_EvaluationChart_customerSay" placeholder="请输入内容"></el-input>
      </div>
      <div class="sr-content">
        <ul>
          <li></li>
          <li>初始裸眼视力</li>
          <li>塑形裸眼视力</li>
          <li>角膜上皮</li>
          <li>结膜</li>
          <li>镜片护理情况</li>
        </ul>
        <ul>
          <li>OD</li>
          <li> <el-input v-model="postData.S_OP_Json.CL_EvaluationChart_IVA_OD" style="margin-right: 30px;"  class="sr" placeholder="请输入内容"></el-input></li>
          <li> <el-input v-model="postData.S_OP_Json.CL_EvaluationChart_CVA_OD" style="margin-right: 30px;"  class="sr" placeholder="请输入内容"></el-input></li>
          <li> <el-input v-model="postData.S_OP_Json.CL_EvaluationChart_ShangPi_OD" style="margin-right: 30px;"  class="sr" placeholder="请输入内容"></el-input></li>
          <li> <el-input v-model="postData.S_OP_Json.CL_EvaluationChart_JieMo_OD" style="margin-right: 30px;"  class="sr" placeholder="请输入内容"></el-input></li>
          <li> <el-input v-model="postData.S_OP_Json.CL_EvaluationChart_HuLi_OD" style="margin-right: 30px;"  class="sr" placeholder="请输入内容"></el-input></li>
        </ul>
        <ul>
          <li>OS</li>
          <li> <el-input v-model="postData.S_OP_Json.CL_EvaluationChart_IVA_OS" style="margin-right: 30px;"  class="sr" placeholder="请输入内容"></el-input></li>
          <li> <el-input v-model="postData.S_OP_Json.CL_EvaluationChart_CVA_OS" style="margin-right: 30px;"  class="sr" placeholder="请输入内容"></el-input></li>
          <li> <el-input v-model="postData.S_OP_Json.CL_EvaluationChart_ShangPi_OS" style="margin-right: 30px;"  class="sr" placeholder="请输入内容"></el-input></li>
          <li> <el-input v-model="postData.S_OP_Json.CL_EvaluationChart_JieMo_OS" style="margin-right: 30px;"  class="sr" placeholder="请输入内容"></el-input></li>
          <li> <el-input v-model="postData.S_OP_Json.CL_EvaluationChart_HuLi_OS" style="margin-right: 30px;"  class="sr" placeholder="请输入内容"></el-input></li>
        </ul>
      </div>
      <div class="xz">
        <div>
          <p>戴镜时间不低于8小时</p>
          <el-radio v-model="postData.S_OP_Json.CL_EvaluationChart_Q1" label="1">是</el-radio>
          <el-radio v-model="postData.S_OP_Json.CL_EvaluationChart_Q1" label="0">否</el-radio>
        </div>
        <div>
          <p>每天更换护理液</p>
          <el-radio v-model="postData.S_OP_Json.CL_EvaluationChart_Q2" label="1">是</el-radio>
          <el-radio v-model="postData.S_OP_Json.CL_EvaluationChart_Q2" label="0">否</el-radio>
        </div>
      </div>
      <div class="xz">
        <div>
          <p>操作前用洗手液洗手</p>
          <el-radio v-model="postData.S_OP_Json.CL_EvaluationChart_Q3" label="1">是</el-radio>
          <el-radio v-model="postData.S_OP_Json.CL_EvaluationChart_Q3" label="0">否</el-radio>
        </div>
        <div>
          <p>吸棒，双联合护理</p>
          <el-radio v-model="postData.S_OP_Json.CL_EvaluationChart_Q4" label="1">是</el-radio>
          <el-radio v-model="postData.S_OP_Json.CL_EvaluationChart_Q4" label="0">否</el-radio>
        </div>
      </div>
      <div class="xz">
        <div style="padding-right: 0;">
          <p>操作用时 (洗摘戴)</p>
          <el-input style="width: 160px;padding: 0;margin-right: 45px" v-model="postData.S_OP_Json.CL_EvaluationChart_duration" placeholder="请输入内容"></el-input>
        </div>
        <div>
          <p>定期修剪指甲</p>
          <el-radio v-model="postData.S_OP_Json.CL_EvaluationChart_Q5" label="1">是</el-radio>
          <el-radio v-model="postData.S_OP_Json.CL_EvaluationChart_Q5" label="0">否</el-radio>
        </div>
      </div>
      <div class="xz">
        <div>
          <p>摘镜前滴润滑液确定镜片活动度后再摘镜</p>
          <el-radio v-model="postData.S_OP_Json.CL_EvaluationChart_Q6" label="1">是</el-radio>
          <el-radio v-model="postData.S_OP_Json.CL_EvaluationChart_Q6" label="0">否</el-radio>
        </div>
        <div>
          <p>每周深度洁净液浸泡(除蛋白)</p>
          <el-radio v-model="postData.S_OP_Json.CL_EvaluationChart_Q7" label="1">是</el-radio>
          <el-radio v-model="postData.S_OP_Json.CL_EvaluationChart_Q7" label="0">否</el-radio>
        </div>
      </div>
      <div class="xz">
        <div>
          <p>是否坚持自觉独立规范配戴</p>
          <el-radio v-model="postData.S_OP_Json.CL_EvaluationChart_Q8" label="1">是</el-radio>
          <el-radio v-model="postData.S_OP_Json.CL_EvaluationChart_Q8" label="0">否</el-radio>
        </div>
        <div>
          <p>喜爱程度</p>
          <el-select style="width: 160px;padding: 0;margin-right: 45px" v-model="postData.S_OP_Json.CL_EvaluationChart_prefer"  filterable allow-create  placeholder="请选择">
            <el-option
                v-for="item in xacd"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="zs">
        <p>使用建议</p>
        <el-input class="sr" v-model="postData.S_OP_Json.CL_EvaluationChart_suggestion" placeholder="请输入内容"></el-input>
      </div>
    </div>


    <div style="width: 900px;margin: 0 auto;padding-top: 30px; text-align: left;">
      <el-button @click="showzysx=true" style="margin-right: 50px;">注意事项</el-button>
      <el-button @click="showayxcs=true">爱眼小常识</el-button>
    </div>

    <div style="padding-top: 30px">
      <el-button @click="$router.back(-1)" style="margin-right: 50px;">返回</el-button>
      <el-button type="primary" @click="save">保存</el-button>
    </div>


    <el-dialog
        title="注意事项"
        :visible.sync="showzysx"
        width="600px"
        class="ts"
        >
      <p>使用角膜塑形镜，务必:</p>
      <p>1、禁止用自来水冲洗镜片。</p>
      <p>2、注意操作台面的整洁、护理套包清洁度、良好个人卫生。</p>
      <p>3、护理产品须在开封有效期内使用，使用后拧盖保存避免污染。</p>
      <p>4、配戴期间如出现:眼红、眼痛、畏光、流泪等异常反应，请立即停戴并及时复诊。</p>
      <p>爱护眼睛控制近视，是需要我们共同努力的!</p>
      <el-button @click="showzysx=false">知道了</el-button>
    </el-dialog>

    <el-dialog
        title="爱眼小常识"
        :visible.sync="showayxcs"
        width="600px"
        class="ts"
    >
      <p>1、养成良好的用眼习惯，正确的握笔姿势和看书习惯;</p>
      <p>2、在家看书写字时，每隔20分钟往6米外远眺20秒;</p>
      <p>3、多户外活动多晒太阳多参加一些体育活动，建议每天户外活动的时间超过2小时;</p>
      <p>4、建议不使用手机、ipad等电子产品;</p>
      <p>5、适当减少课外训练学习班以及乐谱的阅读;</p>
      <p>6、均衡饮食营养不挑食，适当多补充鱼、虾、蛋、肉等富含蛋白质食物，补充绿色蔬菜，补充钙、锌等矿物质和多种维生素的摄入，尽量减少雪糕、甜点等含糖多的食物摄入。</p>
      <el-button @click="showayxcs=false">知道了</el-button>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "sxjsyzhb",
  data() {
    return {
      input: '',
      postData: {
        S_OP_ChooseId: this.$store.state.physicianVisits.xzConsulting,
        S_OP_CustomerId: this.$store.state.users.CsUser.CustomerId,
        S_OP_ExpertId: this.$store.state.users.employees?this.$store.state.users.employees.ExpertId:"00000000-0000-0000-0000-000000000000",
        S_OP_HealthCheckId: '',
        S_OP_ID: '',
        S_OP_Model:"CL_EvaluationChart210712",
        S_OP_Type:"CL_EvaluationChart",
        S_OP_Time: '',
        S_OP_XML: '',
        S_OP_Json: {
          CL_EvaluationChart_CVA_OD: '',
          CL_EvaluationChart_CVA_OS: '',
          CL_EvaluationChart_HuLi_OD: '',
          CL_EvaluationChart_HuLi_OS: '',
          CL_EvaluationChart_IVA_OD: '',
          CL_EvaluationChart_IVA_OS: '',
          CL_EvaluationChart_JieMo_OD: '',
          CL_EvaluationChart_JieMo_OS: '',
          CL_EvaluationChart_Q1: '',
          CL_EvaluationChart_Q2: '',
          CL_EvaluationChart_Q3: '',
          CL_EvaluationChart_Q4: '',
          CL_EvaluationChart_Q5: '',
          CL_EvaluationChart_Q6: '',
          CL_EvaluationChart_Q7: '',
          CL_EvaluationChart_Q8: '',
          CL_EvaluationChart_ShangPi_OD: '',
          CL_EvaluationChart_ShangPi_OS: '',
          CL_EvaluationChart_customerSay: '',
          CL_EvaluationChart_duration: '',
          CL_EvaluationChart_prefer: '',
          CL_EvaluationChart_suggestion: '',
        }
      },
      showzysx: false,
      showayxcs: false,
      xacd: [
        {
          value: '分享',
          label: '分享'
        },
        {
          value: '喜爱',
          label: '喜爱'
        },
        {
          value: '勉强',
          label: '勉强'
        },
        {
          value: '',
          label: ''
        },
      ]
    }
  },

  created() {
    this._api.publicApi.PS_all_Normal_getList('CL_EvaluationChart', 'CL_EvaluationChart210712')
        .then(res => {
          // console.log(res)
          if (res) {
            this.postData = JSON.parse(JSON.stringify(res[0]))
          }
        })
  },

  methods: {
    save() {
      this._api.publicApi.S_ALL_Normal_Save(this.postData)
    }
  }
}
</script>

<style scoped lang="scss">
.sxjsyzhb {
  padding: 20px 30px;
  height: calc(87vh - 40px);
  overflow-y: auto;
}
.sr-content {
  padding: 30px 0;
  ul {
    display: flex;
    align-items: center;
    li{
      width: 120px;
      padding-right: 10px;
    }
    li:nth-child(1) {width: 2em;}
  }
  ul:nth-child(2) {padding-bottom: 10px;}
}
.xz {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  div {
    display: flex;
    align-items: center;
    padding-right: 100px;
    padding-bottom: 15px;
    p {
      width: 10em;
      text-align: left;
      padding-right: 40px;
    }
  }
}
.zs {
  display: flex;
  align-items: center;
  padding-top: 30px;
  .sr {
    width: 750px;
    margin-left: 30px;
  }
}
.ts {
  p{
    text-align: left;
    padding-bottom: 15px;
  }
}
</style>
